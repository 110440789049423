import {BaseApiService} from "@/services/BaseApiService";
import {http} from "@/services/HttpCommon";
import {TerminateRegistrationRequest} from "@/models/TerminateRegistrationRequest";

class TerminateRegistrationService extends BaseApiService {
    async terminateRegistration(terminateRegistrationRequest: TerminateRegistrationRequest) {
        return await http.post(
            `${this.handlerLocation}registration/terminate`,
                terminateRegistrationRequest
        );
    }
}

export default new TerminateRegistrationService();