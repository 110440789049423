import exportFromJSON from "export-from-json";

class ExportDataService {
    exportCSV(data: any, newFileName: string): void {
        if (!data) return;
        try {
            const fileName = newFileName || "exported-data";
            const exportType = exportFromJSON.types.csv;
            exportFromJSON(
                {
                    data,
                    fileName,
                    exportType
                }
            );
        } catch (e) {
            throw new Error("csv export failed");
        }
    }
}

export default new ExportDataService();