<template>
  <nav class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
    <div class="flex align-items-center justify-content-center">
      <font-awesome-icon icon="fa-solid fa-wifi" size="xl" class="text-white" />
      <a class="navbar-brand col-sm-3 col-md-2 mr-0 my-2" href="#"><b>ESA Guest WiFi</b></a>
    </div>
    <div class="navbar-nav px-3">
      <b-dropdown id="logout-dropdown" class="mx-1" right>
        <template #button-content>
          <font-awesome-icon icon="fa-solid fa-circle-user" size="xl" class="text-white mx-2" />
          {{ username }}
        </template>
        <b-dropdown-item @click="logout()">
          <font-awesome-icon icon="fa-solid fa-right-from-bracket" class="mx-2" />
          <span class="ms-1 d-none d-sm-inline text-black">Logout</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </nav>
</template>

<script>
import Vue from 'vue';
import authService from "@/services/AuthService";
import configService from "@/services/ConfigService";

export default Vue.extend({
  name: 'TopNavBar',
  components: {
  },
  data: function () {
    return {
      username: null
    }
  },
  mounted() {
    this.username = authService.getUsername();
  },
  methods: {
    logout() {
      const CONFIG = configService.getConfig();
      if (CONFIG) {
          sessionStorage.clear();
          window.location.href = `https://${CONFIG.VUE_APP_IDP_DOMAIN}/logout?client_id=${CONFIG.VUE_APP_IDP_CLIENT_ID}&${CONFIG.VUE_APP_LOGIN_PARAMS}${CONFIG.VUE_APP_LOGIN_REDIRECT_URL}`;
        }
    }
  },
});
</script>

<style scoped>
#logout-dropdown >>> button {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  box-shadow: none;
}

nav {
  max-height: 56px;
}
</style>