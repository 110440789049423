import {BaseApiService} from "@/services/BaseApiService";
import {http} from "@/services/HttpCommon";

import {HotelGuestDeviceBasicInfo} from "@/models/HotelGuestDeviceBasicInfo";
import {HotelsResponse} from "@/models/HotelsResponse";
import {HotelsViewModel} from "@/models/HotelsViewModel";
import { DeleteDeviceRequest, DeleteDeviceResponse, DeleteVendorDeviceRequest } from "@/models/DeleteDevice";
import { HotelExtendedInfo } from "@/models/HotelExtendedInfo";
import { ENDPOINT_URIS } from "@/constants/EndpointUris";
import { HotelRegisteredDevicesRequest, HotelRegisteredDevicesResponse } from "@/models/HotelRegisteredDevices";
import { HttpStatusCode } from "axios";


class HotelService extends BaseApiService {
    async getAllRegisteredDevicesForHotel(hotelDevicesRequest: HotelRegisteredDevicesRequest): Promise<HotelGuestDeviceBasicInfo[]> {
        const result =  await http.post<HotelRegisteredDevicesResponse>(
            `${this.handlerLocation}hotels/registered/devices`,
            hotelDevicesRequest
        );

        return result.data.hotel_registered_devices;
    }

    async getHotels(): Promise<HotelsViewModel[]> {
        const result = await http.get<HotelsResponse>(
            `${this.handlerLocation}hotels/get`
        );

        return result.data.hotels
            .map<HotelsViewModel>(
                (hotel) => {
                    return new HotelsViewModel(hotel);
                }
            );
    }

    async getHotelExtendedInfo(siteId: string): Promise<HotelExtendedInfo> {
        const result = await http.get<HotelExtendedInfo>(
            `${this.handlerLocation}${ENDPOINT_URIS.GET_HOTEL_EXTENDED_INFO}?site_id=${siteId}`,
        );

        return result.data;

    }


    async updateHotelExtendedInfo(hotelExtendedInfo: HotelExtendedInfo): Promise<boolean> {
        const result = await http.post(
            `${this.handlerLocation}${ENDPOINT_URIS.UPDATE_HOTEL_EXTENDED_INFO}`,
            hotelExtendedInfo
        );

        if (result?.status != HttpStatusCode.Ok) {
            throw new Error("Update Failed!");
        }

        return true;

    }
    
    async deleteDevice(deviceRequest: DeleteDeviceRequest): Promise<DeleteDeviceResponse> {
        const result = await http.delete(
          `${this.handlerLocation}devices/delete`,
          {
            data: JSON.stringify(deviceRequest),
          }
        );
    
        return result.data;
    }

    async deleteVendorDevice(deviceRequest: DeleteVendorDeviceRequest): Promise<DeleteDeviceResponse> {
        const result = await http.delete(
          `${this.handlerLocation}vendor/devices/delete`,
          {
            data: JSON.stringify(deviceRequest),
          }
        );
    
        return result.data;
    }
}

export default new HotelService();
