
import { makeToast, ToastVariant } from "@/utils/toast";
import { IVendorCode } from '@/models/IVendorCode';
import Vue from 'vue';
import { UPDATE_ACCESS_CODES_MESSAGES, ACCESS_CODES_VALIDATION_MESSAGES, GET_ACCESS_CODES_MESSAGES } from "@/constants/messages";
import "../styles/guest-wifi-form.scss";
import AccessCodesService from "@/services/VendorCodesService";
import { AddAccessCodesRequest, UpdateAccessCodesRequest } from "@/models/VendorCodesRequest";
import "../styles/guest-wifi-form.scss";

interface ITableItem extends IVendorCode {
    code_expires_on_date: string,
    code_expires_on_time: string,
    isNewRow: boolean,
    isEdited: boolean,
    errors: string[],
    _showDetails: boolean   // this is a bootstrap-vue table newRow that determines if the row-details slot is show or not
}

export default Vue.extend({
    name: "VendorcodesForm",
    props: [
        'hotel',
        'closeModal'
    ],
    data() {
        return {
            edit: "",
            originalData: new Array<IVendorCode>(),
            items: new Array<ITableItem>(),
            fields: [
                { key: "access_code", label: "Access Code", sortable: true },
                { key: "download_speed", label: "Download Speed (kbps)", sortable: false },
                { key: "upload_speed", label: "Upload Speed (kbps)", sortable: false },
                { key: "plan_duration", label: "Plan Duration (days)", sortable: false },
                { key: "code_expires_on_date", label: "Code Expration Date (UTC)", sortable: true },
                { key: "code_expires_on_time", label: "Code Expration Time (UTC)", sortable: false },
                { key: "actions", label: "Actions", sortable: false },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortBy: "property_id",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            isTableLoading: true,

            previousData: new Array<IVendorCode>(),

            previousValue: '',

            showModal: false,
            isSuccess: false,
            isError: false,
            isDataEdited: false
        }
    },
    mounted() {
        this.fetchAccessCodesForSiteId()
    },

    methods: {
        validateAccessCode(event: Event, key: string, item: ITableItem) {
            let eventTarget = event.target as HTMLInputElement;
            let value = eventTarget.value;
            item.isEdited = true;
            item.errors = [];
            if (!value) {
                item.errors.push(ACCESS_CODES_VALIDATION_MESSAGES.ERROR_ACCESS_CODE);
            } else {
                item[key] = value;
            }
            item._showDetails = (item.errors.length > 0);

        },
        numberFormatter(event: Event, key: string, item: ITableItem) {
            let eventTarget = event.target as HTMLInputElement;
            let value = eventTarget.value;
            item.isEdited = true;
            item.errors = [];
            if (['download_speed', 'upload_speed', 'plan_duration'].includes(key)) {
                if (value != item[key]) {
                    this.isDataEdited = true;
                }
                if (!value || /^\d+$/.test(value)) {
                    item[key] = value;
                } else {
                    eventTarget.value = item[key].toString();
                }
            }
            item._showDetails = (item.errors.length > 0);

        },
        dateFormatter(event: Event, key: string, item: ITableItem) {
            item.isEdited = true;
            item.code_expires_on = `${item.code_expires_on_date}T${item.code_expires_on_time}Z`
        },
        hideExpired(item: ITableItem, filterValue: string) {
            return new Date(item.code_expires_on) > new Date() || item.isEdited === true && this.filter === 'Hide'
        },
        fetchNewRow() {
            return this.items.find(x => x.isNewRow && !x.isEdited);

        },
        fetchEditedRow() {
            return this.items.find(x => x.isEdited);
        },
        rowClass(item: ITableItem, type: string) {
            if (!item || type !== 'row') return
            if (item.isEdited === true) return 'table-success'
        },
        addNewRow() {
            const newRow = this.fetchNewRow();
            if (newRow != null) {
                // if an unedited new row exisits do not add another one
                return
            }
            const currentDate = new Date().toISOString();
            const newItem: ITableItem = {
                vendor_code_id: 0,
                access_code: '',
                download_speed: 0,
                upload_speed: 0,
                plan_duration: 0,
                code_expires_on: currentDate,
                code_expires_on_date: currentDate.split('T')[0],
                code_expires_on_time: currentDate.split('T')[1].split('Z')[0].split('.')[0],
                isNewRow: true,
                isEdited: false,
                errors: [],
                _showDetails: false
            };
            this.items.push(newItem);
            this.$emit('input', this.items)
        },
        deleteRow(row: any) {
            const targetItem = row.item;
            if (targetItem.isNewRow) {
                // Remove the row if its a new blank row added at the bottom
                this.items.splice(row.index, 1);
            } else {
                // Expire the vendor code date by updating it, this doesn't remove the row
                const currentDate = new Date().toISOString();
                targetItem.code_expires_on = currentDate;     
                const [date, time] = currentDate.split('T');
                targetItem.code_expires_on_date = date;
                targetItem.code_expires_on_time = time.split('.')[0];
            }
            targetItem.isEdited = true;
            this.$emit('input', this.items);
        },
        onFiltered(filteredItems: IVendorCode[]) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async fetchAccessCodesForSiteId() {
            try {
                const accessCodesResponse = await AccessCodesService.getAllAccessCodesForHotel({ site_id: this.hotel.site_id });
                if (accessCodesResponse && accessCodesResponse.vendor_codes) {
                    this.items = accessCodesResponse.vendor_codes.map(x => ({
                        ...x,
                        plan_duration: Math.round(x.plan_duration / 60 / 60 / 24),
                        code_expires_on_date: x.code_expires_on.split('T')[0],
                        code_expires_on_time: x.code_expires_on.split('T')[1].split('Z')[0].split('.')[0],
                        isNewRow: false,
                        isEdited: false,
                        errors: [],
                        _showDetails: false
                    }));
                }

            } catch (error) {
                console.warn(error);
                makeToast(
                    ToastVariant.danger,
                    GET_ACCESS_CODES_MESSAGES.ERROR_TOAST_TITLE,
                    GET_ACCESS_CODES_MESSAGES.ERROR_TOAST_BODY(this.hotel.site_id)
                );
            } finally {
                this.isTableLoading = false;
            }
        },
        async handleSubmit() {

            try {
                this.isError = false;
                const addAccessCodes: AddAccessCodesRequest = { site_id: this.hotel.site_id, vendor_codes: [] };
                const updateAccessCodes: UpdateAccessCodesRequest = { site_id: this.hotel.site_id, vendor_codes: [] };
                for (const item of this.items) {
                    if (!item.access_code || item.access_code.trim() == '') {
                        item['errors'] = []
                        item.errors.push(ACCESS_CODES_VALIDATION_MESSAGES.ERROR_ACCESS_CODE)
                        this.isError = true;
                        item._showDetails = true;
                    }
                    if (!this.isError) {
                        const accessCode = {
                            vendor_code_id: item.vendor_code_id,
                            access_code: item.access_code,
                            upload_speed: Number(item.upload_speed),
                            download_speed: Number(item.download_speed),
                            plan_duration: Number(item.plan_duration) * 60 * 60 * 24,
                            code_expires_on: `${item.code_expires_on_date}T${item.code_expires_on_time}Z`
                        }
                        if (item.isEdited) {
                            if (item.isNewRow) {
                                addAccessCodes.vendor_codes.push(accessCode)
                            } else {
                                updateAccessCodes.vendor_codes.push(accessCode)
                            }
                        }
                        // else ignore that row
                    }
                }

                if (this.isError) {
                    this.$emit('input', this.items)
                    return false;
                }

                if (addAccessCodes.vendor_codes.length > 0) {
                    await AccessCodesService.addAccessCodesForHotel(addAccessCodes);

                }
                if (updateAccessCodes.vendor_codes.length > 0) {
                    await AccessCodesService.updateAccessCodesForHotel(updateAccessCodes);
                }

                makeToast(
                    ToastVariant.success,
                    UPDATE_ACCESS_CODES_MESSAGES.SUCCESS_TOAST_TITLE,
                    UPDATE_ACCESS_CODES_MESSAGES.SUCCESS_TOAST_BODY(this.hotel.site_id)
                );

                this.filter = null;

                // refresh form with udpated data to confirm data was updated in the database
                await this.fetchAccessCodesForSiteId();

            } catch (error) {
                this.isError = true;
                console.log(error);
                makeToast(
                    ToastVariant.danger,
                    UPDATE_ACCESS_CODES_MESSAGES.ERROR_TOAST_TITLE,
                    UPDATE_ACCESS_CODES_MESSAGES.ERROR_TOAST_BODY(this.hotel.site_id)
                );
            }

        }
    }


});
