import axios, { AxiosRequestConfig } from "axios";
import { showPageSpinner, hidePageSpinner } from "./Spinner";
import configService from "@/services/ConfigService";

const CONFIG = configService.getConfig();

const skipSpinnerFlag = false;
const http = axios.create({
  baseURL: `${CONFIG?.VUE_APP_API_DOMAIN}`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});

const httpPost = axios.create({
  baseURL: `${CONFIG?.VUE_APP_API_DOMAIN}`,
  headers: {
    "Content-type": "text/plain"
  },
  method: "POST"
});

const getSkipSpinnerFlag = () => {
  return skipSpinnerFlag;
};

const getCookie = (name: string) => {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(";")
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};


const addAuthHeaderToRequest = (config: AxiosRequestConfig) => {
  const token = sessionStorage.getItem('guestwifi_admin_auth');
  if (token) {
    config.headers = config.headers ?? {};
    config.headers["x-guestwifi-auth"] = token;
  }
};


const getBrowserIdentCookie = () => {
  return getCookie("esa_browseridentifier");
};
const addBrowserIdentHeaderToRequest = (config: AxiosRequestConfig) => {
  const browserIdentCookie = getBrowserIdentCookie();
  if (browserIdentCookie) {
    config.headers = config.headers ?? {};
    config.headers["x-browseridentifier"] = browserIdentCookie;
  }
};

// before a request - start the spinner
http.interceptors.request.use(config => {
  if(!getSkipSpinnerFlag()){
    showPageSpinner();
  }
  addAuthHeaderToRequest(config);
  addBrowserIdentHeaderToRequest(config);
  return config;
});

httpPost.interceptors.request.use(config => {
  if(!getSkipSpinnerFlag()){
    showPageSpinner();
  }

  addAuthHeaderToRequest(config);
  addBrowserIdentHeaderToRequest(config);
  return config;
});

// after the response - stop the spinner
http.interceptors.response.use(
  response => {
    hidePageSpinner();
    return response;
  },
  err => {
    hidePageSpinner();
    throw err;
  }
);

httpPost.interceptors.response.use(
  response => {
    hidePageSpinner();
    return response;
  },
  err => {
    hidePageSpinner();
    throw err;
  }
);

export { getCookie, http, httpPost };
