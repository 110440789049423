export const DELETE_DEVICE_MESSAGES = {
  SUCCESS_TOAST_TITLE: "Device Deleted",
  SUCCESS_TOAST_BODY: (macAddress: string) =>
    `Device with mac address ${macAddress} has been deleted.`,
  ERROR_TOAST_TITLE: "Failed to delete device",
  ERROR_TOAST_BODY: (macAddress: string) =>
    `An error has occurred while trying to delete a device with mac address ${macAddress}.`,
  CONFIRMATION_MODAL_TITLE: "Delete Device Confirmation",
  CONFIRMATION_MODAL_BODY: (macAddress?: string) =>
    `Do you want to delete the selected device with mac address <span><code> ${macAddress} </code></span> permanently?`,
};

export const UPDATE_EXTENDED_INFO_MESSAGES = {
  SUCCESS_TOAST_TITLE: "Hotel extended info updated!",
  SUCCESS_TOAST_BODY: (siteId: string) =>
    `Hotel extended info for hotel site id ${siteId} has been updated.`,
  ERROR_TOAST_TITLE: "Failed to update Hotel extended info!",
  ERROR_TOAST_BODY: (siteId: string) =>
    `An error has occured while trying to update hotel extended device for hotel site id ${siteId}.`,
  INVALID_IP_ADDRESS : "Please enter valid IP address",  
};

export const UPDATE_ACCESS_CODES_MESSAGES = {
  SUCCESS_TOAST_TITLE: "Access Codes Details Updated!",
  SUCCESS_TOAST_BODY: (siteId: string) =>
    `Access Codes Details for hotel site id ${siteId} has been updated.`,
  ERROR_TOAST_TITLE: "Failed to update Access Codes Details!",
  ERROR_TOAST_BODY: (siteId: string) =>
    `An error has occured while trying to update Access Codes Details for hotel site id ${siteId}.`,
};

export const ADD_DEVICE_MESSAGES = {
  SUCCESS_TOAST_TITLE: "Device(s) Added",
  SUCCESS_TOAST_BODY: "Device(s) added successfully.",
  ERROR_TOAST_TITLE: "Add Device(s) Failed",
  ERROR_TOAST_BODY: "Failed to Add device(s)",
  DUPLICATE_MAC_ADDRESS_ERROR_TITLE: "Duplicate Mac Addresses Found",
  DUPLICATE_MAC_ADDRESS_ERROR_BODY:
    "Please remove the entered duplicate mac addresses and try again.",
};

export const TERMINATE_REGISTRATION_MESSAGES = {
  TERMINATION_REASON_DD_TITLE: "Please select a termination reason",
  SUCCESS_TOAST_TITLE: "Registration Terminated",
  SUCCESS_TOAST_BODY: (lastName: string, roomNumber: number) =>
      `Registration for ${lastName} in room ${roomNumber} has been terminated.`,
  ERROR_TOAST_TITLE: "Failed to terminate registration",
  ERROR_TOAST_BODY: (lastName: string, roomNumber: number) =>
      `An error has occurred while trying to terminate a registration for ${lastName} in room ${roomNumber}.`,
  CONFIRMATION_MODAL_TITLE: "Terminate Registration Confirmation",
  CONFIRMATION_MODAL_BODY: (lastName: string, roomNumber: number) =>
      `Do you want to terminate registration for <span><code>${lastName}</code></span> in room <span><code>${roomNumber}</code></span> permanently?`,
};

export const GET_ACCESS_CODES_MESSAGES = {
  ERROR_TOAST_TITLE: "Failed to fetch Access Codes Details!",
  ERROR_TOAST_BODY: (siteId: string) =>
    `An error has occured while trying to fetch Access Codes Details for hotel site id ${siteId}.`,
};

export const ACCESS_CODES_VALIDATION_MESSAGES = {
  ERROR_ACCESS_CODE: "Please enter a valid access code.",
  ERROR_EXPIRATION_DATE: "Please enter a valid plan expiration date."
}
