import {BaseApiService} from "@/services/BaseApiService";
import {http} from "@/services/HttpCommon";

import { ENDPOINT_URIS } from "@/constants/EndpointUris";
import { AddAccessCodesRequest, GetAccessCodesRequest, UpdateAccessCodesRequest } from "@/models/VendorCodesRequest";
import { AccessCodesResponse } from "@/models/VendorCodesResponse";
import { HttpStatusCode } from "axios";


class VendorCodesService extends BaseApiService {

    async addAccessCodesForHotel(addAccessCodesRequest: AddAccessCodesRequest): Promise<boolean> {
        const result = await http.post(
            `${this.handlerLocation}${ENDPOINT_URIS.ADD_HOTEL_ACCESS_CODES}`,
            addAccessCodesRequest
        );

        if (result?.status != HttpStatusCode.Ok) {
            throw new Error("Adding Vendor Codes Failed!");
        }

        return true;

    }
    
    async getAllAccessCodesForHotel(accessCodesRequest: GetAccessCodesRequest): Promise<AccessCodesResponse> {
        const result =  await http.get<AccessCodesResponse>(
            `${this.handlerLocation}${ENDPOINT_URIS.GET_HOTEL_ACCESS_CODES}?site_id=${accessCodesRequest.site_id}`
        );

        return result.data;
    }

    async updateAccessCodesForHotel(updateAccessCodesRequest: UpdateAccessCodesRequest): Promise<boolean> {
        const result = await http.post(
            `${this.handlerLocation}${ENDPOINT_URIS.UPDATE_HOTEL_ACCESS_CODES}`,
            updateAccessCodesRequest
        );

        if (result?.status != HttpStatusCode.Ok) {
            throw new Error("Updating Vendor Codes Failed!");
        }

        return true;

    }
    
}

export default new VendorCodesService();
