import {HotelBasicInfo} from "@/models/HotelBasicInfo";
import {HelperService} from "@/services/HelperService";

export class HotelsViewModel {
    constructor(hotelsBasicInfo: HotelBasicInfo) {
        this.property_id = hotelsBasicInfo.id;
        this.site_id = hotelsBasicInfo.site_id;
        this.property_name = hotelsBasicInfo.name;

        const address = {
            street1: hotelsBasicInfo.address1,
            street2: hotelsBasicInfo.address2,
            city: hotelsBasicInfo.city,
            state: hotelsBasicInfo.state,
            zip: hotelsBasicInfo.zip
        };

        this.property_address = HelperService.concatAddress(address, ', ');
        this.nse_id = hotelsBasicInfo.nse_id;
        this.ip_address = hotelsBasicInfo.ip_address;
    }

    property_id: bigint;
    site_id: string;
    property_name: string;
    property_address: string;
    nse_id: string;
    ip_address: string;
}