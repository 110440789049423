
import { makeToast, ToastVariant } from "@/utils/toast";
import { HotelExtendedInfo } from '@/models/HotelExtendedInfo';
import HotelService from '@/services/HotelService';
import Vue from 'vue';
import { UPDATE_EXTENDED_INFO_MESSAGES } from "@/constants/messages";
import "../styles/guest-wifi-form.scss";
import authService from "@/services/AuthService";
import { USER_TYPES } from "@/constants/UserTypes";

export default Vue.extend({
    name: "BandwidthForm",
    props: [
        'hotel',
        'closeModal'
    ],
    data() {
        return {
            originalData: {} as HotelExtendedInfo,
            formData: {} as HotelExtendedInfo,
            previousData: {} as HotelExtendedInfo,
            previousValue: '',
            showModal: false,
            isSuccess: false,
            isError: false,
            isDataEdited: false,
            errorMessage: '',
            isEmployee: authService.isUser(USER_TYPES.EMPLOYEE)
        }
    },
    mounted() {
        this.fetchHotelExtendedInfo()
    },
    watch: {
        formData: {
            handler(newData): void {
                this.isDataEdited = (JSON.stringify(newData) != JSON.stringify(this.originalData))
            },
            deep: true
        }

    },
    methods: {
        validateInput(e: Event) {
            let eventTarget = e.target as HTMLInputElement;
            if (eventTarget.value == '') {
                eventTarget.value = '0'
                return true;
            }
            if (eventTarget.validity.valid) {
                eventTarget.value = parseInt(eventTarget.value).toString();
                return true;
            }
            eventTarget.value = this.previousValue;
            e.preventDefault()
            return false;

        },
        validateIpAddres(e: Event) {
            let ipAddress = e.target as HTMLInputElement;
            this.errorMessage = "";
            let regex = new RegExp(/^(([0-9]{1,3}\.){3}[0-9]{1,3})$/);
            let ipAddresscheck = true;
            if(regex.test(ipAddress.value)){
                let arInput = ipAddress.value.split(".")
                for(let i of arInput){
                    if(i.length > 1 && i.charAt(0) === '0')
                        ipAddresscheck = false;
                    else{
                        if(parseInt(i) < 0 || parseInt(i) >=256)
                            ipAddresscheck = false;
                    }
                }
            }
            else {
                ipAddresscheck = false;
            }
            if(!ipAddresscheck) {
                this.errorMessage = UPDATE_EXTENDED_INFO_MESSAGES.INVALID_IP_ADDRESS;
                this.isDataEdited = false;
                return false;
            }
            else {
                this.isDataEdited = true;
                return true;
            }      
        },
        savePreviousValue(e: Event) {
            let eventTarget = e.target as HTMLInputElement;
            if (eventTarget.validity.valid) {
                this.previousValue = eventTarget.value
            }
        },
        async fetchHotelExtendedInfo() {
            try {
                const extendedInfo = await HotelService.getHotelExtendedInfo(this.hotel.site_id);
                this.originalData = { ...extendedInfo };
                this.formData = { ...this.originalData };

            } catch (error) {
                console.warn(error);
            } finally {
                this.showModal = true;
                this.errorMessage = "";
            }
        },
        async handleSubmit() {

            try {
                const hotelExtendedInfo: HotelExtendedInfo = {
                    site_id: this.formData.site_id,
                    free_upload_speed: Number(this.formData.free_upload_speed),
                    free_download_speed: Number(this.formData.free_download_speed),
                    free_max_devices: Number(this.formData.free_max_devices),
                    buyup_upload_speed: Number(this.formData.buyup_upload_speed),
                    buyup_download_speed: Number(this.formData.buyup_download_speed),
                    buyup_max_devices: Number(this.formData.buyup_max_devices),
                    ip_address: this.formData.ip_address
                }

                await HotelService.updateHotelExtendedInfo(hotelExtendedInfo);

                makeToast(
                    ToastVariant.success,
                    UPDATE_EXTENDED_INFO_MESSAGES.SUCCESS_TOAST_TITLE,
                    UPDATE_EXTENDED_INFO_MESSAGES.SUCCESS_TOAST_BODY(this.formData.site_id)
                );
                this.$emit('updatedextendedInfo', true);
                // refresh form with udpated data to confirm data was updated in the database
                await this.fetchHotelExtendedInfo();

            } catch (error) {
                this.isError = true;
                console.log(error);
                makeToast(
                    ToastVariant.danger,
                    UPDATE_EXTENDED_INFO_MESSAGES.ERROR_TOAST_TITLE,
                    UPDATE_EXTENDED_INFO_MESSAGES.ERROR_TOAST_BODY(this.formData.site_id)
                );
            }

        }
    }


});
