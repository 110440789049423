import { BModal } from "bootstrap-vue";

export const showMessageBox = (title: string, body: string) => {
  new BModal().$bvModal.msgBoxOk(body, {
    title: title,
    size: "md",
    buttonSize: "sm",
    okVariant: "success",
    headerClass: "border-bottom-0",
    footerClass: "p-2 border-top-0",
    centered: false,
  });
};
