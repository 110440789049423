import { library, dom } from "@fortawesome/fontawesome-svg-core";

// Free icons
import {
  faSpinner,
  faUserSecret,
  faHouse,
  faHotel,
  faLaptop,
  faChartLine,
  faWifi,
  faCircleUser,
  faRightFromBracket,
  faTrashCan,
  faEdit,
  faCheck,
  faUsers,
  faAngleLeft,
  faAngleRight,
  faSquarePlus,
  faPlus,
  faMinus,
  faDatabase,
  faCalendarDays
} from "@fortawesome/free-solid-svg-icons";

class FontAwesomeService {
  loadAndWatch(): void {
    // see https://www.pullrequest.com/blog/webpack-fontawesome-guide/
    [
      faSpinner,
      faUserSecret,
      faHouse,
      faHotel,
      faLaptop,
      faChartLine,
      faTrashCan,
      faWifi,
      faCircleUser,
      faRightFromBracket,
      faEdit,
      faCheck,
      faUsers,
      faAngleLeft,
      faAngleRight,
      faSquarePlus,
      faPlus,
      faMinus,
      faDatabase,
      faCalendarDays
    ].forEach((x) => library.add(x));
    dom.watch();
  }
}

export default new FontAwesomeService();
