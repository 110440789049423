import $ from "jquery";

const showPageSpinner = () => {
  $(".esa-spinner-page").first().fadeIn();
};

const hidePageSpinner = () => {
  $(".esa-spinner-page").fadeOut(0);
};

$(() => {
  $(".esa-spinner-page").fadeOut(0);
});

export { showPageSpinner, hidePageSpinner };
