import {RegistrationBasicInfo} from "@/models/RegistrationBasicInfo";

export class RegistrationsViewModel {
    constructor(registrationBasicInfo: RegistrationBasicInfo) {
        this.registration_id = registrationBasicInfo.registration_id;
        this.last_name = registrationBasicInfo.last_name;
        this.first_name =  registrationBasicInfo.first_name,
        this.confirm_num = registrationBasicInfo.confirm_num,
        this.email = registrationBasicInfo.email,
        this.plan_id = registrationBasicInfo.plan_id,
        this.room_number = registrationBasicInfo.room_number;
        this.plan_name = registrationBasicInfo.plan_name;
        this.expiration_date = registrationBasicInfo.expiration_date;
        this.connected_devices = registrationBasicInfo.connected_devices;
        this.allowed_devices = registrationBasicInfo.allowed_devices;
        this.bandwidth_upload = registrationBasicInfo.bandwidth_upload;
        this.bandwidth_download = registrationBasicInfo.bandwidth_download;
        this.price = registrationBasicInfo.price;
    }
    registration_id: number;
    last_name: string;
    first_name: string;
    confirm_num: number;
    email: string;
    plan_id: number;
    room_number: number;
    plan_name: string;
    expiration_date: Date;
    connected_devices: number;
    allowed_devices: number;
    bandwidth_upload: number;
    bandwidth_download: number;
    price: number;
}