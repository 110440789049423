import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import HotelsView from "@/views/HotelsView.vue"
import HotelView from "@/views/HotelView.vue"
import HotelGuestDevicesView from "@/views/HotelGuestDevicesView.vue"
import PageNotFoundView from '@/views/PageNotFoundView.vue'
import authService from "@/services/AuthService";
import RegistrationsView from "@/views/RegistrationsView.vue";
import BuyUpReportView from "@/reports/BuyUpReportView.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Hotels',
        component: HotelsView,
        meta: {title: 'Hotels'}
    },
    {
        path: '/reports/buy_up',
        name: 'BuyUpReport',
        component: BuyUpReportView,
        meta: {title: 'BuyUp Report'}
    },
    {
        path: '/hotels/:id',
        component: HotelView,
        children: [
            {
                path: '',
                name: "HotelHome",
                component: HotelView
            },
            {
                path: ':name/guest_devices',
                name: "HotelGuestDevices",
                component: HotelGuestDevicesView,
                props: true,
                meta: {title: 'Guest Devices'}
            },
            {
                path: ':name/registrations',
                name: "Registrations",
                component: RegistrationsView,
                props: true,
                meta: {title: 'Registered Users'}
            }
        ]
    },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        components: {
            default: PageNotFoundView,
        },
        meta: {title: 'Page Not Found'}
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {

    if (!await authService.processAuth()) {
        next(false);
        return;
    }

    const routeRecord = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (routeRecord) {
        document.title = routeRecord.meta.title;
    }
    next();

});

export default router
