
import Vue from "vue";
import authService from "@/services/AuthService";
import HotelService from "@/services/HotelService";
import { HotelsViewModel } from "@/models/HotelsViewModel";
import HotelExtendedInfoModal from "@/views/modals/HotelExtendedInfoModal.vue";
import { PAGINATION_PROPERTIES } from "@/constants/PaginationProperties";
import { USER_TYPES } from "@/constants/UserTypes";

export default Vue.extend({
  components: {
    HotelExtendedInfoModal
  },
  data() {
    return {
      items: new Array<HotelsViewModel>(),
      fields: [
        {key: "property_id", label: "Property Id", sortable: true},
        {key: "site_id", label: "Site Id", sortable: true},
        {key: "property_name", label: "Property Name", sortable: true},
        {key: "property_address", label: "Property Address", sortable: true},
        {key: "nse_id", label: "Gateway Id (NSEID)", sortable: true},
        {key: "ip_address", label: "IP Address", sortable: true},
        {key: "actions", label: "Actions", sortable: false},
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: PAGINATION_PROPERTIES.PER_PAGE,
      pageOptions: PAGINATION_PROPERTIES.PAGE_OPTIONS,
      sortBy: "property_id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      isTableLoading: true,
      hotel: {} as HotelsViewModel,
      isUser: authService.isUser,
      userTypes: USER_TYPES,
      mountExtendedInfoModal: false,
      updatedextendedInfo: false,
    };
  },
  mounted() {
    this.fetchHotels();
  },
  methods: {
    async fetchHotels() {
      try {
        this.items = await HotelService.getHotels();
        this.totalRows = this.items.length;
      } catch (error) {
        console.warn(error);
      } finally {
        this.isTableLoading = false;
      }
    },
    updatedhotelextendedInfo() {
      this.fetchHotels();     
    },
    onFiltered(filteredItems: HotelsViewModel[]) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filterHotels(item: HotelsViewModel, searchString: string) {
      const values = Object.values((({...o}) => o)(item));
      const searchStringLowerCased = searchString.toLocaleLowerCase();
      if (
          values.toString().toLocaleLowerCase().includes(searchStringLowerCased)
      ) {
        return true;
      }
    },
    editExtendedInfo(item: HotelsViewModel) {
      this.hotel = Object.assign({}, item)
      this.mountExtendedInfoModal = true;
    }
  },
});
