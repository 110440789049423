<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <div
        class="d-flex flex-column align-items-center align-items-sm-start text-white min-vh-100 sidebar bg-light"
      >
        <ul
          class="nav flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu">
          <li class="nav-item w-100">
            <router-link to="/" class="nav-link">
              <font-awesome-icon icon="fa-solid fa-hotel" class="mr-2" />
              <span class="ms-1 d-none d-sm-inline">Hotels</span>
            </router-link>
          </li>
          <li class="nav-item w-100">
            <router-link to="/reports/buy_up" class="nav-link">
              <font-awesome-icon icon="fa-solid fa-database" class="mr-2" />
              <span class="ms-1 d-none d-sm-inline">Reports</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div
        :class="
          'col pt-3 px-4 main-view ' +
          (showSidebar === true ? 'show-sidebar' : 'hide-sidebar')
        "
      >
        <div
          class="toggle-icon"
          @click="showSidebar = !showSidebar"
          :class="showSidebar === true ? 'show-sidebar' : 'hide-sidebar'"
        >
          <font-awesome-icon
            v-if="showSidebar === true"
            icon="fa-solid fa-angle-left"
            size="xs"
          />
          <font-awesome-icon
            v-if="showSidebar === false"
            icon="fa-solid fa-angle-right"
            size="xs"
          />
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ContentArea",
  data: function () {
    return {
      showSidebar: true,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/sidebar.scss";
</style>
