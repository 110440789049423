export class HelperService {
    static concatAddress(address: { zip: string; city: string; street1: string; street2: string; state: string }, separator: string) {
        return Object.values(address)
            .filter((val) => val)
            .join(separator);
    }

    static formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = (1 + date.getMonth()).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return month + '/' + day + '/' + year;
    }
}

export default new HelperService();