<template>
  <b-container fluid>
    <h2 v-show="$route.name === 'BuyUpReport'"><b>Buy-Up Report</b></h2>
    <br/>
    <template>
      <b-row>
        <b-col align-h="end" lg="12" class="mt-4">
          <b-form-group label-align-sm="right" class="mb-0">
            <b-input-group>
              <date-range-picker
                  v-model="dateRange"
                  :show-week-numbers=true
                  :show-dropdowns=true
                  :auto-apply=true
                  opens="right"
                  :close-on-esc=true
                  :locale-data="localeData"
                  :date-format="dateFormat" class="paddedItem">
                <template #input="picker">
                  <font-awesome-icon icon="fa-solid fa-calendar-days" style="color: #42b983"/>
                  {{ picker.startDate | date }} ~ {{ picker.endDate | date }}
                </template>
              </date-range-picker>
              <b-button pill class="reportButtons" @click="generateReport" id="generateReportBtn">Generate Report
              </b-button>
              <span id="disable-wrapper" tabindex="0">
                <b-button pill class="reportButtons" @click="exportData"
                          :disabled="filteredItems.length === 0">
                  Export CSV
                </b-button>
              </span>
              <b-tooltip target="generateReportBtn" placement="right" triggers="hover">
                Gets the BuyUp information for the date range specified.
              </b-tooltip>
              <b-tooltip v-if="filteredItems.length === 0" target="disable-wrapper" placement="right" triggers="hover">
                No information to export, select a date range and click on <code>Generate Report</code>.
              </b-tooltip>
              <b-tooltip v-if="filteredItems.length > 0" target="disable-wrapper" placement="right" triggers="hover">
                This will only export filtered records.
              </b-tooltip>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" class="mt-4">
          <b-form-group label-align-sm="right" class="mb-0">
            <b-input-group>
              <b-form-input
                  id="filter-input"
                  debounce="500"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search">
              </b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = null">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        striped
        hover
        outlined
        no-border-collapse
        :filter-function="filterHotels"
        :busy="isTableLoading"
        head-variant="dark"
        class="mt-2"
        @filtered="onFiltered">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>
    </b-table>
    <div class="d-flex w-100 justify-content-center">
      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="my-0 bg-dark"
      ></b-pagination>
    </div>
  </b-container>
</template>

<script>

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import ExportDataService from "@/services/ExportDataService";
import ReportService from "@/services/ReportService";

export default {
  name: "BuyUpReportView",
  components: {
    DateRangePicker
  },
  data() {
    return {
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      localeData: {
        weekLabel: 'W',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 0,
      },
      items: [],
      filteredItems: [],
      fields: [
        {key: "site_id", label: "Site Id", sortable: true},
        {key: "site_name", label: "Site Name", sortable: true},
        {key: "site_address", label: "Site Address", sortable: true},
        {
          key: "registration_date", label: "Registered On", sortable: true,
          formatter: (value) => {
            const date = new Date(value);
            return date.toDateString();
          }
        },
        {key: "plan_type", label: "Plan Type", sortable: true},
        {
          key: "number_registrations", label: "Registrations", sortable: true,
          formatter: (value) => {
            let formatter = new Intl.NumberFormat("en-US");
            return formatter.format(value);
          }
        },
        {
          key: "revenue", label: "Revenue", sortable: true,
          formatter: (value) => {
            let formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2
            });
            return formatter.format(value);
          }
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: "property_id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      isTableLoading: false,
    }
  },
  mounted() {
    this.items = []
    this.filteredItems = []
  },
  methods: {
    async getBuyUpReport() {
      try {
        this.items = await ReportService.getBuyUpReport({
          startDate: this.dateRange.startDate,
          endDate: this.dateRange.endDate
        });
        this.filteredItems = this.items;
        this.totalRows = this.items.length;
      } catch (error) {
        console.warn(error);
      } finally {
        this.isTableLoading = false;
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.filteredItems = filteredItems;
    },
    filterHotels(item, searchString) {
      const values = Object.values((({...o}) => o)(item));
      const searchStringLowerCased = searchString.toLocaleLowerCase();
      if (
          values.toString().toLocaleLowerCase().includes(searchStringLowerCased)
      ) {
        return true;
      }
    },
    generateReport() {
      this.getBuyUpReport();
      this.filter = null;
    },
    exportData() {
      if (this.filteredItems.length > 0) {
        ExportDataService.exportCSV(this.filteredItems, "BuyUpReport_" + Date.now());
      }
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0,0,0,0);
        classes.disabled = date.getTime() >= tomorrow
      }
      return classes
    }
  },
  filters: {
    date(val) {
      return val ? val.toDateString() : ''
    }
  }
}
</script>

<style scoped>
.reportButtons {
  background-color: #73c04d;
  border-color: #73c04d;
  color: #fefefe;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.paddedItem{
  margin-bottom: 0.5rem;
}
</style>