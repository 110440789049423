import { CognitoJwtVerifier } from "aws-jwt-verify";
import configService from "@/services/ConfigService";
import jwt_decode from "jwt-decode";
import { IDToken } from "@/models/Auth";

const TOKEN_NAME = 'guestwifi_admin_auth';
class AuthService {

    readonly CONFIG = configService.getConfig();

    async isUserAuthenticated(): Promise<boolean> {
        const token = sessionStorage.getItem(TOKEN_NAME);
        if (token) {
            return await this.verifyToken(token);
        }
        return false;
    }

    private async verifyToken(token: string): Promise<boolean> {
        const verifier = CognitoJwtVerifier.create({
            userPoolId: `${this.CONFIG?.VUE_APP_IDP_USERPOOL_ID}`,
            tokenUse: "id",
            clientId: `${this.CONFIG?.VUE_APP_IDP_CLIENT_ID}`,
        });

        try {
            const payload = await verifier.verify(token);
            return true
        } catch (err) {
            console.warn("Token not valid!", err);
            return false
        }
    }

    async processAuth(): Promise<boolean> {
        const hash = new URLSearchParams(window.location.hash);
        const token = hash.get('#id_token');
        if (token) {
            if (await this.verifyToken(token)) {
                const decoded: IDToken = jwt_decode(token);
                if (decoded) {
                    sessionStorage.setItem(TOKEN_NAME, token)
                    sessionStorage.setItem('username', `${decoded?.given_name} ${decoded?.family_name}`)
                    window.location.href = `${this.CONFIG?.VUE_APP_LOGIN_REDIRECT_URL}`
                    return true;
                } else {
                    return false;
                }
            } else {
                window.location.href = `https://${this.CONFIG?.VUE_APP_IDP_DOMAIN}${this.CONFIG?.VUE_APP_LOGIN_URL}?client_id=${this.CONFIG?.VUE_APP_IDP_CLIENT_ID}&${this.CONFIG?.VUE_APP_LOGIN_PARAMS}${this.CONFIG?.VUE_APP_LOGIN_REDIRECT_URL}`
                return false;
            }
        }
        if (!token && !(await this.isUserAuthenticated())) {
            window.location.href = `https://${this.CONFIG?.VUE_APP_IDP_DOMAIN}${this.CONFIG?.VUE_APP_LOGIN_URL}?client_id=${this.CONFIG?.VUE_APP_IDP_CLIENT_ID}&${this.CONFIG?.VUE_APP_LOGIN_PARAMS}${this.CONFIG?.VUE_APP_LOGIN_REDIRECT_URL}`
            return false;
        } else {
            return true;
        }
    }

    getUsername() {
        return sessionStorage.getItem('username');
    }

    isUser(userType: string) {
        const token = sessionStorage.getItem(TOKEN_NAME);
        if (!token) {
            return false;
        }

        const decoded: IDToken = jwt_decode(token);
        if (!decoded || !decoded.identities) {
            return false;
        }

        return decoded.identities.filter(x => x.providerName === userType).length > 0;
    }

}
export default new AuthService();
