
import Vue from "vue";
import VueCookies from 'vue-cookies';
import TopNavBar from "@/components/TopNavBar.vue";
import ContentArea from "@/components/ContentArea.vue";
import configService from "@/services/ConfigService";
import authService from "@/services/AuthService";

Vue.use(VueCookies);

export default Vue.extend({
  components: {
    TopNavBar,
    ContentArea
  },
  data() {
    return {
      isAuthenticated: false
    }
  },
  async created() {
    this.isAuthenticated = await authService.processAuth();
  },
  methods: {
    appendScriptToHead(src: string) {
      const appScript = document.createElement("script");
      appScript.setAttribute("src", src);
      appScript.setAttribute("type", "text/javascript");
      document.head.appendChild(appScript);
    },
    async mounted() {
      const ts = new Date().toISOString();

      const CONFIG = configService.getConfig();

      this.appendScriptToHead(
        `${CONFIG?.VUE_APP_BASE_DOMAIN}application/themes/esa/vendors~app.bundle.js?v=${ts}`
      );
      this.appendScriptToHead(
        `${CONFIG?.VUE_APP_BASE_DOMAIN}application/themes/esa/app.bundle.js?v=${ts}`
      );
    }
  }
});
