
import Vue from 'vue';
import BandwidthForm from "@/components/BandwidthFormComponent.vue";
import VendorCodesForm from "@/components/VendorCodesFormComponent.vue";
import authService from "@/services/AuthService";
import { USER_TYPES } from "@/constants/UserTypes";

export default Vue.extend({
    name: "ExtenedInfoModal",
    components: {
        BandwidthForm,
        VendorCodesForm 
    },
    props: ['hotel'],
    data() {
        return {
            showModal: false,
            isDataEdited: false,
            isUser: authService.isUser,
            userTypes: USER_TYPES
        }
    },

    methods: {
        // callback from components
        closeModal() {
            this.showModal = false;
        },

        emitRefreshHotelData() {
            this.$emit("updatedextendedInfoChild");
        }
    }


});
