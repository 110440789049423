import {BaseApiService} from "@/services/BaseApiService";
import {http} from "@/services/HttpCommon";
import {RegistrationsResponse} from "@/models/RegistrationsResponse";
import {RegistrationsViewModel} from "@/models/RegistrationsViewModel";
import {RegistrationsRequest} from "@/models/RegistrationsRequest";
import { AddDeviceRequest } from "@/models/AddDevice";

class RegistrationService extends BaseApiService {
    async getRegistrationsForHotel(registrationsRequest: RegistrationsRequest): Promise<RegistrationsViewModel[]> {
        const result = await http.get<RegistrationsResponse>(
            `${this.handlerLocation}registrations/get?site_id=${registrationsRequest.site_id}`
        );

        return result.data.registrations
            .map<RegistrationsViewModel>(
                (registration) => {
                    return new RegistrationsViewModel(registration);
                }
            );
    }

    async addDevices(userDevices: AddDeviceRequest) {
        const result = await http.post(`${this.handlerLocation}guest/devices/add`, userDevices);
        return result.data;
    }
}

export default new RegistrationService();