
import Vue from "vue";
import HotelService from "@/services/HotelService";
import { HotelGuestDeviceBasicInfo } from "@/models/HotelGuestDeviceBasicInfo";
import { makeToast, ToastVariant } from "@/utils/toast";
import { DELETE_DEVICE_MESSAGES } from "@/constants/messages";
import { PAGINATION_PROPERTIES } from "@/constants/PaginationProperties";

export default Vue.extend({
  props: {
    name: String,
    id: String,
  },
  data() {
    return {
      items: new Array<HotelGuestDeviceBasicInfo>(),
      fields: [
        { key: "first_name", label: "First Name", sortable: true },
        { key: "last_name", label: "Last Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "room_number", label: "Room Number", sortable: true },
        { key: "access_code", label: "Passcode", sortable: true },
        { key: "mac_address", label: "MAC Address", sortable: true },
        { key: "plan_tier", label: "Plan Tier", sortable: true },
        {
          key: "plan_expiration_date",
          label: "Plan Expiration Date",
          sortable: true,
        },
        {
          key: "gateway_only_device",
          label: "Nomadix Device",
          sortable: false,
        },
        { key: "actions", label: "Actions", sortable: false },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: PAGINATION_PROPERTIES.PER_PAGE,
      pageOptions: PAGINATION_PROPERTIES.PAGE_OPTIONS,
      sortBy: "last_name",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      deviceToDelete: null as HotelGuestDeviceBasicInfo | null,
      isTableLoading: true,
      deleteModalTitle: DELETE_DEVICE_MESSAGES.CONFIRMATION_MODAL_TITLE,
    };
  },
  async mounted() {
    this.fetchDevices();
  },
  methods: {
    confirmationModalBody: DELETE_DEVICE_MESSAGES.CONFIRMATION_MODAL_BODY,
    onFiltered(filteredItems: HotelGuestDeviceBasicInfo[]) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(value: Date) {
      const date = new Date(value);
      return date.toLocaleString("en-US");
    },
    async fetchDevices() {
      try {
        this.items = await HotelService.getAllRegisteredDevicesForHotel({
          site_id: this.id,
        });
        this.totalRows = this.items.length;
      } catch (error) {
        console.warn(error);
      } finally {
        this.isTableLoading = false;
      }
    },
    filterDevices(item: HotelGuestDeviceBasicInfo, searchString: string) {
      const values = Object.values(
        (({ plan_expiration_date, ...o }) => o)(item)
      );
      const searchStringLowerCased = searchString.toLocaleLowerCase();
      if (
        values.toString().toLocaleLowerCase().includes(searchStringLowerCased)
      ) {
        return true;
      } else {
        const date = this.formatDate(
          item.plan_expiration_date
        ).toLocaleLowerCase();
        return date.includes(searchStringLowerCased);
      }
    },
    async deleteDevice(hide: any) {
      try {
        if (this.deviceToDelete) {
          if (this.deviceToDelete?.access_code) {
            await HotelService.deleteVendorDevice({
              mac_address: this.deviceToDelete?.mac_address,
              site_id: this.id,
              access_code: this.deviceToDelete?.access_code,
            });
          } else {
            await HotelService.deleteDevice({
              mac_address: this.deviceToDelete?.mac_address,
              site_id: this.id,
              gateway_only_device: this.deviceToDelete.gateway_only_device,
            });
          }
          makeToast(
            ToastVariant.success,
            DELETE_DEVICE_MESSAGES.SUCCESS_TOAST_TITLE,
            DELETE_DEVICE_MESSAGES.SUCCESS_TOAST_BODY(
              this.deviceToDelete?.mac_address
            )
          );
        }
      } catch (err) {
        makeToast(
          ToastVariant.danger,
          DELETE_DEVICE_MESSAGES.ERROR_TOAST_TITLE,
          DELETE_DEVICE_MESSAGES.ERROR_TOAST_BODY(
            `${this.deviceToDelete?.mac_address}`
          ),
          false
        );
        console.warn(err);
      } finally {
        await this.fetchDevices();
        hide();
      }
    },
    refreshDevicesTable: async function () {
      await this.fetchDevices();
    },
  },
});
