export default {
    local: {
        VUE_APP_API_DOMAIN: 'http://localhost:8010/proxy',
        VUE_APP_BASE_DOMAIN: 'http://localhost:8080/',
        VUE_APP_IDP_DOMAIN: 'dev-esa-guest-wifi.auth.us-east-1.amazoncognito.com',
        VUE_APP_LOGIN_URL: '/login',
        VUE_APP_LOGIN_REDIRECT_URL: 'http://localhost:8080/',
        VUE_APP_LOGIN_PARAMS: 'response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=',
        VUE_APP_IDP_CLIENT_ID: '2na7slc7ljfufhg55phv661j5l',
        VUE_APP_IDP_USERPOOL_ID: 'us-east-1_1Ma3bJy25'
    },
    dev: {
        VUE_APP_API_DOMAIN: '/',
        VUE_APP_BASE_DOMAIN: '/',
        VUE_APP_IDP_DOMAIN: 'dev-esa-guest-wifi.auth.us-east-1.amazoncognito.com',
        VUE_APP_LOGIN_URL: '/login',
        VUE_APP_LOGIN_REDIRECT_URL: 'https://www.dev.wifiadminportal.extendedstayamerica.com/',
        VUE_APP_LOGIN_PARAMS: 'response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=',
        VUE_APP_IDP_CLIENT_ID: '2na7slc7ljfufhg55phv661j5l',
        VUE_APP_IDP_USERPOOL_ID: 'us-east-1_1Ma3bJy25'
    },
    test: {
        VUE_APP_API_DOMAIN: '/',
        VUE_APP_BASE_DOMAIN: '/',
        VUE_APP_IDP_DOMAIN: 'test-esa-guest-wifi.auth.us-east-1.amazoncognito.com',
        VUE_APP_LOGIN_URL: '/login',
        VUE_APP_LOGIN_REDIRECT_URL: 'https://www.test.wifiadminportal.extendedstayamerica.com/',
        VUE_APP_LOGIN_PARAMS: 'response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=',
        VUE_APP_IDP_CLIENT_ID: '3voj73hngvr16vua33pidue2fm',
        VUE_APP_IDP_USERPOOL_ID: 'us-east-1_vGPEvD48r'
    },
    uat: {
        VUE_APP_API_DOMAIN: '/',
        VUE_APP_BASE_DOMAIN: '/',
        VUE_APP_IDP_DOMAIN: 'uat-esa-guest-wifi.auth.us-east-1.amazoncognito.com',
        VUE_APP_LOGIN_URL: '/login',
        VUE_APP_LOGIN_REDIRECT_URL: 'https://www.uat.wifiadminportal.extendedstayamerica.com/',
        VUE_APP_LOGIN_PARAMS: 'response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=',
        VUE_APP_IDP_CLIENT_ID: '7i8v2okq79cgijunq3fgvl7h00',
        VUE_APP_IDP_USERPOOL_ID: 'us-east-1_epswNF3Dl'
    },
    prod: {
        VUE_APP_API_DOMAIN: '/',
        VUE_APP_BASE_DOMAIN: '/',
        VUE_APP_IDP_DOMAIN: 'prod-esa-guest-wifi.auth.us-east-1.amazoncognito.com',
        VUE_APP_LOGIN_URL: '/login',
        VUE_APP_LOGIN_REDIRECT_URL: 'https://www.prod.wifiadminportal.extendedstayamerica.com/',
        VUE_APP_LOGIN_PARAMS: 'response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=',
        VUE_APP_IDP_CLIENT_ID: '6csign1fub3c6cf669vsmka01e',
        VUE_APP_IDP_USERPOOL_ID: 'us-east-1_eeE1jvRpm'
    }
}

