export const ENDPOINT_URIS = {
  GET_GUEST_DEVICES_BY_HOTEL_SITE_ID: "hotels/registrations/devices/get",
  GET_HOTELS: "hotels/get",
  GET_HOTEL_EXTENDED_INFO: "hotels/extendedInfo/get",
  UPDATE_HOTEL_EXTENDED_INFO: "hotels/extendedInfo/update",
  DELETE_GUEST_DEVICES: "devices/delete",
  ADD_HOTEL_ACCESS_CODES: "hotels/vendor_codes/add",
  GET_HOTEL_ACCESS_CODES: "hotels/vendor_codes/get",
  UPDATE_HOTEL_ACCESS_CODES: "hotels/vendor_codes/update"
};


