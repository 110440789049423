import {BaseApiService} from "@/services/BaseApiService";
import {http} from "@/services/HttpCommon";
import {TerminationReasonsOption} from "@/models/TerminationReasonsOption";
import {TerminationReasonsResponse} from "@/models/TerminationReasonsResponse";

class TerminationReasonService extends BaseApiService {
    async getTerminationReasons(): Promise<TerminationReasonsOption[]> {
        const result = await http.get<TerminationReasonsResponse>(
            `${this.handlerLocation}terminationReasons/get`
        );

        return result.data.termination_reasons
            .map<TerminationReasonsOption>(
                (terminationReason) => {
                    return {
                            value: {
                                termination_reason_id: terminationReason.termination_reason_id,
                                reason: terminationReason.reason
                            },
                            text: terminationReason.display_label
                    };
                }
            );
    }
}

export default new TerminationReasonService();