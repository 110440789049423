import {BaseApiService} from "@/services/BaseApiService";
import {http} from "@/services/HttpCommon";
import {BuyUpReportRequest} from "@/models/BuyUpReportRequest";
import {BuyUpReportResponse} from "@/models/BuyUpReportResponse";
import {BuyUpReportViewModel} from "@/models/BuyUpReportViewModel";
import {HelperService} from "@/services/HelperService";

class ReportService extends BaseApiService {
    async getBuyUpReport(buyUpReportRequest: BuyUpReportRequest): Promise<BuyUpReportViewModel[]> {
        const result = await http.get<BuyUpReportResponse>(
            `${this.handlerLocation}reports/buyup/get?`
            + `start_date=${HelperService.formatDate(buyUpReportRequest.startDate)}&`
            + `end_date=${HelperService.formatDate(buyUpReportRequest.endDate)}`
        );

        return result.data.buy_ups
            .map<BuyUpReportViewModel>(
                (buyUpReportBasicInfo) => {
                    return new BuyUpReportViewModel(buyUpReportBasicInfo);
                }
            );
    }
}

export default new ReportService();