import CONFIG from "@/configs/config";
class ConfigService {

    public getConfig() {

        const result = window.location.host.match(new RegExp('www.' + "(.*)" + '.wifiadmin'));
        if (result) {
            const env = result[1];
            return CONFIG[env as keyof typeof CONFIG]
        }
        if (window.location.host.startsWith('localhost')) {
            return CONFIG.local
        } else {
            return null;
        }
    }
}
export default new ConfigService();