import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin, TablePlugin, ButtonPlugin, AlertPlugin, LinkPlugin, FormInputPlugin, InputGroupPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./app.scss";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FontAwesomeService from "./services/FontAwesomeService";

FontAwesomeService.loadAndWatch();

Vue.config.devtools = true;
Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(TablePlugin);
Vue.use(ButtonPlugin);
Vue.use(AlertPlugin);
Vue.use(LinkPlugin);
Vue.use(FormInputPlugin);
Vue.use(InputGroupPlugin);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");