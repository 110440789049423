import { BToast } from "bootstrap-vue";

export enum ToastVariant {
  default,
  danger,
  info,
  primary,
  secondary,
  success,
  warning,
}

export function makeToast(
  variant: ToastVariant,
  title: string,
  body: string,
  autoHide = true
) {
  new BToast().$bvToast.toast(body, {
    title,
    variant: ToastVariant[variant],
    solid: true,
    autoHideDelay: 5000,
    noAutoHide: !autoHide,
  });
}
