import {BuyUpReportBasicInfo} from "@/models/BuyUpReportBasicInfo";
import {HelperService} from "@/services/HelperService";

export class BuyUpReportViewModel {
    constructor(buyUpReportBasicInfo: BuyUpReportBasicInfo) {
        this.site_id = buyUpReportBasicInfo.site_id;
        this.site_name = buyUpReportBasicInfo.site_name;

        const address = {
            street1: buyUpReportBasicInfo.address1,
            street2: buyUpReportBasicInfo.address2,
            city: buyUpReportBasicInfo.city,
            state: buyUpReportBasicInfo.state,
            zip: buyUpReportBasicInfo.zip
        };

        this.site_address = HelperService.concatAddress(address, ', ');
        this.registration_date = buyUpReportBasicInfo.registration_date;
        this.plan_type = buyUpReportBasicInfo.plan_type;
        this.number_registrations = buyUpReportBasicInfo.number_registrations;
        this.revenue = buyUpReportBasicInfo.revenue;
    }

    site_id: string;
    site_name: string;
    site_address: string;
    registration_date: Date;
    plan_type: string;
    number_registrations: number;
    revenue: number;
}